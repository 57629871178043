import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../util/style";
import { formatValue } from "../detail_payment.hooks";
import { PaymentStatusBadge } from "../parts/CusomStyle";
import DocumentIcon from "../../../../assets/document.png";
import {
  createAliasForCategory,
  detailTypeOf,
  getTimeFromDate,
} from "../parts/imutable_state";
import { formatDate, formatSize } from "../../../../util/function";
import ApprovalLine from "./ApprovalLine";

/**
 *
 * @param {{ mode:'PAYMENT'|'PAYMENT_REQUEST' }} param0
 * @returns
 */
const DetailPR = ({ detailDocument, attachmentAltenative, mode }) => {
  const { renderValue } = formatValue(detailDocument);

  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    paid: { bgColor: "#B0CDE7", colors: "#1268B8" },
    // Add more statuses as needed
  };

  const statusInfo = statusColors[detailDocument["Status"]] || {
    bgColor: infoSurfaceColor,
    colors: infoMainColor,
  };

  const validateValue = (value, key) => {
    //* validate if value is object;
    /**@type {boolean} */
    const isObject =
      Object.prototype.toString.call(value) === detailTypeOf.object;
    if (isObject) {
      switch (key) {
        case "Client":
          return value.name || "-";
        case "Request To":
          return (
            <span>
              {value.name} &bull; {value.position}
            </span>
          );
      }
    } else {
      switch (key) {
        case "Category":
          return createAliasForCategory(value);
        case "Create At":
          return `${renderValue(value, key, false)} ${getTimeFromDate(value)}`;
        default:
          return renderValue(value, key, false);
      }
    }
  };

  const RenderApproval = () => {
    const dateApprove = new Date(detailDocument["Approve At"]);
    const userApproved =
      detailDocument?.Status === "Rejected Payment"
        ? detailDocument["Rejected By"]
        : detailDocument["Verified By"];

    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Typography variant="body1">
            {(detailDocument?.Status === "Verified"
              ? "Approved"
              : detailDocument?.Status === "Rejected Payment" && "Rejected") ||
              "Approved"}{" "}
            By:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {userApproved || ""} {DotCircleGreen({ size: 7 })}{" "}
          {dateApprove.toLocaleDateString("id-ID", {
            day: "2-digit",
            month: "numeric",
            year: "numeric",
          })}
        </Grid>
        <Grid item xs={12}>
          <Typography>Reason:</Typography>
          <Typography>{detailDocument?.Reason?.Approval}</Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const renderPropertyFromObject = () => {
    const listRenderProperty = {
      "No. Reference": "No. Reference",
      Client: null,
      Category: "Category",
      Service: null,
      "Sub Service": null,
      "Create At": "Create At",
      "Due Date": "Due Date",
      Total: "Total",
      "Created By": null,
      "Request To": null,
      "Pay At": "Pay At",
      // Note: "Note",
      Comment: "Comment",
    };

    if (mode === "PAYMENT") {
      listRenderProperty["Created By"] = "Request By";
    } else {
      listRenderProperty["Request To"] = "Request To";
    }

    if (detailDocument.Category === "Services") {
      listRenderProperty.Service = "Service";
      listRenderProperty["Sub Service"] = "Sub Service";
      listRenderProperty.Client = "Client Name";
    }

    return (
      <>
        {Object.entries(detailDocument).map(([key, value]) => {
          return Object.keys(listRenderProperty).includes(key) &&
            listRenderProperty[key] !== null ? (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  {listRenderProperty[key]}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1">
                  {validateValue(value, key)}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null;
        })}
        <Grid item xs={4}>
          <Typography variant="body1">Attachment</Typography>
        </Grid>

        {detailDocument.Attachment && <RenderAttachmentList />}

        <React.Fragment>
          <Grid item xs={4}>
            <Typography variant="body1">Payment Attachment</Typography>
          </Grid>
        </React.Fragment>
        {detailDocument.paymentAttachment ? (
          <RenderPaymentAttachment />
        ) : (
          <Grid item xs={8}>
            -
          </Grid>
        )}

        {/* {mode === "PAYMENT" && detailDocument?.Status !== "Approved" && (
          <RenderApproval />
        )} */}
        <ApprovalLine detailDocument={detailDocument} />
      </>
    );
  };

  const RenderPaymentAttachment = () => {
    const paymentAttachment = detailDocument?.paymentAttachment;

    return (
      <Grid
        xs={8}
        container
        alignItems={"center"}
        rowGap={1}
        paddingY={1}
        paddingLeft={"0.5rem"}
      >
        {Array.isArray(paymentAttachment) && paymentAttachment.length > 0 ? (
          paymentAttachment.map((attach) => (
            <React.Fragment key={attach.name}>
              <Grid xs={1}>
                <img
                  src={DocumentIcon}
                  alt="File"
                  style={{ paddingInline: ".4rem" }}
                />
              </Grid>
              <Grid xs={9}>
                <Typography>
                  <span style={{ fontWeight: "500" }}>{attach.filename}</span>
                  &nbsp;<span style={{ fontSize: "1.4rem" }}>&bull;</span>&nbsp;
                  <span style={{ color: "#000", opacity: "70%" }}>
                    {formatSize(attach.size)}
                  </span>
                </Typography>
              </Grid>
              <Grid xs={2}>
                <a
                  href={attach.url}
                  title="Open in new"
                  target="_blank"
                  download={attach.name}
                  style={{
                    textDecoration: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                  }}
                >
                  Open
                </a>
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <div style={{ marginLeft: ".6rem" }}>-</div>
        )}
      </Grid>
    );
  };
  const RenderAttachmentList = () => {
    /**@type {any[]} */
    let attachList;
    if (attachmentAltenative !== null && attachmentAltenative !== undefined) {
      attachList = attachmentAltenative;
    } else {
      attachList = detailDocument.Attachment.attachment;
    }
    return (
      <Grid
        xs={8}
        container
        alignItems={"center"}
        rowGap={1}
        paddingY={1}
        paddingLeft={"0.5rem"}
      >
        {Array.isArray(attachList) && attachList.length > 0 ? (
          attachList.map((attach) => (
            <React.Fragment key={attach.filename}>
              <Grid xs={1}>
                <img
                  src={DocumentIcon}
                  alt="File"
                  style={{ paddingInline: ".4rem" }}
                />
              </Grid>
              <Grid xs={9}>
                <Typography>
                  <span style={{ fontWeight: "500" }}>{attach.filename}</span>
                  &nbsp;<span style={{ fontSize: "1.4rem" }}>&bull;</span>&nbsp;
                  <span style={{ color: "#000", opacity: "70%" }}>
                    {formatSize(attach.size)}
                  </span>
                </Typography>
              </Grid>
              <Grid xs={2}>
                <a
                  href={attach.url}
                  title="Open in new"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                  }}
                >
                  Open
                </a>
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <div style={{ marginLeft: ".6rem" }}>-</div>
        )}
      </Grid>
    );
  };

  /**
   *
   * @param {{ size: string|undefined, color:string|undefined }} props
   * @returns
   */
  const DotCircleGreen = (props) => (
    <span
      style={{
        display: "inline-block",
        width: props?.size || ".8rem",
        height: props?.size || ".8rem",
        borderRadius: "100%",
        background: props?.color || "#751314",
      }}
    />
  );

  const RenderApprovalLine = () => {
    //* ====== Component ===========
    /**
     *
     * @param {string} [dateCreate]
     * @returns
     */
    const renderTimeStamp = (dateCreate) => {
      const dateOption = {
        location: "id-ID",
        option: { year: "numeric", month: "short", day: "numeric" },
      };
      if (!dateCreate) {
        dateCreate = detailDocument["Date Created"];
      }
      const valueToRender = renderValue(
        dateCreate,
        "Create At",
        false,
        dateOption
      );

      const result = valueToRender ? String(valueToRender) : "";
      return result.concat(" ", getTimeFromDate(dateCreate));
    };

    const setColorStatus = (status) => {
      return statusColors[status.toLowerCase()].colors || "#000";
    };

    if (detailDocument["Approval Line"]) {
      return (
        <React.Fragment>
          {detailDocument["Approval Line"].map((approval) => (
            <Grid container key={approval["created_at"]}>
              <Grid
                item
                xs={1}
                style={{
                  paddingInline: ".5rem",
                  paddingTop: "1.1rem",
                  paddingBottom: ".2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // gap: ".1rem",
                }}
              >
                <Box>{DotCircleGreen()}</Box>
                <span
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: "3px",
                    borderRadius: "5px",
                    background: "#F5F5F5",
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Box display={"flex"} marginY={".3rem"}>
                  <Typography
                    fontWeight={"600"}
                    fontSize={"1.1rem"}
                    variant="body1"
                  >
                    {approval?.user_fullname}{" "}
                  </Typography>
                  <Typography paddingInline=".5rem">
                    {DotCircleGreen({ size: ".5rem" })}{" "}
                  </Typography>
                  <Typography
                    fontWeight={"400"}
                    fontSize={"1.1rem"}
                    variant="body1"
                  >
                    {approval?.position}
                  </Typography>
                </Box>
                <Box display={"flex"} marginY={".3rem"} alignItems={"center"}>
                  <Grid>{renderTimeStamp(approval?.updated_at)}</Grid>
                  <Grid paddingInline=".5rem">
                    {DotCircleGreen({ size: ".3rem" })}{" "}
                  </Grid>
                  <Grid
                    color={setColorStatus(approval?.status)}
                    fontWeight={600}
                  >
                    {approval?.status}
                  </Grid>
                </Box>
                <Box marginY={".3rem"}>
                  {/* Comment approval */}
                  <Typography variant="body1">{approval?.reason}</Typography>
                </Box>
              </Grid>
            </Grid>
          ))}

          {/* Approval payment request created */}

          <Grid container key={"Payment Requested"}>
            <Grid
              item
              xs={1}
              style={{
                paddingInline: ".5rem",
                paddingTop: "1.1rem",
                paddingBottom: ".2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // gap: ".1rem",
              }}
            >
              <Box>{DotCircleGreen()}</Box>
              <span
                style={{
                  display: "inline-block",
                  height: "100%",
                  width: "3px",
                  borderRadius: "5px",
                  background: "#F5F5F5",
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Box display={"flex"} marginY={".3rem"}>
                <Typography
                  fontWeight={"400"}
                  fontSize={"1.1rem"}
                  variant="body1"
                >
                  Payment Requested
                </Typography>
              </Box>
              <Box display={"flex"} marginY={".3rem"} alignItems={"center"}>
                <Grid>{renderTimeStamp()}</Grid>
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  };

  /**@param {string} value */
  const manipulateStatus = (value) => {
    switch (value) {
      case "Approved":
        if (mode === "PAYMENT") {
          return "Waiting Verification";
        } else {
          return value;
        }
      case "Verified":
        return "Waiting Payment";
      default:
        return value;
    }
  };

  return (
    <>
      {/* -------------------------------- Detail Payment Request ------------------------------- */}
      <Grid
        container
        spacing={2}
        sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, p: 3 }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              marginBottom: 2,
              ...justifyContentEnd,
              ...alignItemsCenter,
            }}
          >
            {detailDocument.hasOwnProperty("Status") && (
              <PaymentStatusBadge
                status={detailDocument["Status"].toLowerCase()}
              >
                {detailDocument["Status"] === "Approved"
                  ? "Waiting Payment"
                  : detailDocument["Status"]}
              </PaymentStatusBadge>
            )}
          </Box>
        </Grid>

        {renderPropertyFromObject()}
        {/* {mode === "PAYMENT_REQUEST" && (
          <Grid xs={12} marginTop={"1rem"} marginLeft={"1.2rem"}>
            <Typography fontWeight={"700"}>Status</Typography>
            <RenderApprovalLine />
          </Grid>
        )} */}
      </Grid>
    </>
  );
};

export default DetailPR;
